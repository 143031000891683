<template>
  <div class="content page-box">
    <div class="list-title">
      <h4 class="title">{{pageType == 'edit' ? '合同详情' : '草稿文件'}}</h4>
    </div>
    <section class="content-box">
      <el-form
        ref="contractForm"
        :model="contractForm"
        :rules="formRules"
        label-width="115px"
        size="small"
      >
        <!-- 基础信息 -->
        <div class="detail-card">
          <header class="card-header">
            <!-- <h4 class="second-title">基础信息</h4> -->
            <TitleBar
              :title="'基础信息'"
              :innerBtnBox="innerBtnBox"
              :hasStatus="hasStatus"
            >
              <div
                slot="btnBox"
                v-if="showSerial"
              >
                <button
                  class="btn btn-primary"
                  @click="goApproveDetail"
                >
                  审批详情
                </button>
              </div>
            </TitleBar>
          </header>
          <section class="card-content">
            <el-row :gutter="20">
              <el-col :span="6">
                <el-form-item
                  label="合同类型:"
                  prop="contractType"
                >
                  <el-select
                    v-model="contractForm.contractType"
                    style="width: 100%"
                    :popper-append-to-body="false"
                  >
                    <template
                      v-for="(item, index) of contypeList"
                      style="left: 0px"
                    >
                      <el-option
                        :key="index"
                        :label="item.label"
                        :value="item.value"
                      />
                    </template>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item
                  label="合同名称:"
                  prop="contractName"
                >
                  <el-input
                    maxlength="100"
                    v-model="contractForm.contractName"
                    @keydown.enter.prevent.native="handle"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item
                  label="经办人:"
                  prop="operatorName"
                >
                  <el-input
                    maxlength="100"
                    v-model="contractForm.operatorName"
                    disabled
                  />
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item
                  label="经办日期:"
                  prop="contractDate"
                >
                  <el-date-picker
                    v-model="contractForm.contractDate"
                    type="date"
                    placeholder="选择日期"
                    value-format="yyyy-MM-dd"
                    @change="dateChange"
                  >
                  </el-date-picker>
                  <span
                    class="date-tip"
                    v-show="dateTip"
                  >请注意：所选日期早于当前日期</span>
                </el-form-item>
              </el-col>
              <!-- <el-col :span="6">
                <el-form-item
                  label="经办部门:"
                  prop="contractDept"
                >
                  <el-select
                    v-model="contractForm.contractDept"
                    style="width: 100%"
                    :popper-append-to-body="false"
                  >
                    <template
                      v-for="(item, index) of orgList"
                      style="left: 0px"
                    >
                      <el-option
                        :key="index"
                        :label="item.orgName"
                        :value="item.orgCode"
                      />
                    </template>
                  </el-select>
                </el-form-item>
              </el-col> -->
            </el-row>
            <el-row :gutter="20">
              <el-col :span="6">
                <el-form-item
                  label="关联项目:"
                  prop="projectGoalFlag"
                >
                  <el-select
                    v-model="contractForm.projectGoalFlag"
                    style="width: 100%"
                    :popper-append-to-body="false"
                    @change="correlateOptions"
                  >
                    <el-option
                      label="非项目目的"
                      value="N"
                    />
                    <el-option
                      label="项目目的"
                      value="Y"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item
                  class="correlate"
                  key="1"
                  v-if="contractForm.projectGoalFlag == 'N'"
                >
                  <el-input
                    disabled
                    placeholder="-"
                  />
                </el-form-item>
                <el-form-item
                  class="correlate"
                  key="2"
                  prop="relevanceProjectInfo"
                  v-else
                >
                  <!-- 关联项目选项 -->
                  <el-select
                    v-model="contractForm.relevanceProjectInfo"
                    style="width: 100%"
                    :popper-append-to-body="false"
                    filterable
                    multiple
                  >
                    <div
                      slot=""
                      class="correlate-options"
                    >
                      <el-tabs v-model="activeName">
                        <el-tab-pane
                          label="全部"
                          name="0"
                        >
                          <template>
                            <div
                              v-loading="correlateLoading"
                              class="options-selects"
                            >
                              <el-option
                                v-for="(item, index) of correlateListAll"
                                :key="index"
                                :label="item.name"
                                :value="item.id"
                              >
                                <p class="options-line">
                                  <span
                                    class="options-name"
                                    v-maia-ellipsis
                                  >{{ item.name }}</span>
                                  <span class="options-type">{{ item.type ==='8'? '直投': item.typeName }}</span>
                                </p>
                              </el-option>
                            </div>
                          </template>
                        </el-tab-pane>
                        <el-tab-pane
                          label="直投"
                          name="8"
                        >
                          <template>
                            <div class="options-selects">
                              <el-option
                                v-for="(item, index) of correlateListClew"
                                :key="index"
                                :label="item.name"
                                :value="item.id"
                              >
                                <p class="options-line">
                                  <span
                                    class="options-name"
                                    v-maia-ellipsis
                                  >{{ item.name }}</span>
                                  <span class="options-type">直投</span>
                                </p>
                              </el-option>
                            </div>
                          </template>
                          <p
                            v-if="correlateListClew.length == 0"
                            class="el-select-dropdown__empty"
                          >暂无数据</p>
                        </el-tab-pane>
                        <el-tab-pane
                          label="一级基金"
                          name="4"
                        >
                          <template>
                            <div class="options-selects">
                              <el-option
                                v-for="(item, index) of correlateListStairFund"
                                :key="index"
                                :label="item.name"
                                :value="item.id"
                              >
                                <p class="options-line">
                                  <span
                                    class="options-name"
                                    v-maia-ellipsis
                                  >{{ item.name }}</span>
                                  <span class="options-type">{{ item.typeName }}</span>
                                </p>
                              </el-option>
                            </div>
                          </template>
                          <p
                            v-if="correlateListStairFund.length == 0"
                            class="el-select-dropdown__empty"
                          >暂无数据</p>
                        </el-tab-pane>
                        <el-tab-pane
                          label="二级基金"
                          name="5"
                        >
                          <template>
                            <div class="options-selects">
                              <el-option
                                v-for="(item, index) of correlateListSecondaryFund"
                                :key="index"
                                :label="item.name"
                                :value="item.id"
                              >
                                <p class="options-line">
                                  <span
                                    class="options-name"
                                    v-maia-ellipsis
                                  >{{ item.name }}</span>
                                  <span class="options-type">{{ item.typeName }}</span>
                                </p>
                              </el-option>
                            </div>
                          </template>
                          <p
                            v-if="correlateListSecondaryFund.length == 0"
                            class="el-select-dropdown__empty"
                          >暂无数据</p>
                        </el-tab-pane>
                        <el-tab-pane
                          label="股票"
                          name="6"
                        >
                          <template>
                            <div class="options-selects">
                              <el-option
                                v-for="(item, index) of correlateListStock"
                                :key="index"
                                :label="item.name"
                                :value="item.id"
                              >
                                <p class="options-line">
                                  <span
                                    class="options-name"
                                    v-maia-ellipsis
                                  >{{ item.name }}</span>
                                  <span class="options-type">{{ item.typeName }}</span>
                                </p>
                              </el-option>
                            </div>
                          </template>
                          <p
                            v-if="correlateListStock.length == 0"
                            class="el-select-dropdown__empty"
                          >暂无数据</p>
                        </el-tab-pane>
                        <el-tab-pane
                          label="债券"
                          name="2"
                        >
                          <template>
                            <div class="options-selects">
                              <el-option
                                v-for="(item, index) of correlateListBond"
                                :key="index"
                                :label="item.name"
                                :value="item.id"
                              >
                                <p class="options-line">
                                  <span
                                    class="options-name"
                                    v-maia-ellipsis
                                  >{{ item.name }}</span>
                                  <span class="options-type">{{ item.typeName }}</span>
                                </p>
                              </el-option>
                            </div>
                          </template>
                          <p
                            v-if="correlateListBond.length == 0"
                            class="el-select-dropdown__empty"
                          >暂无数据</p>
                        </el-tab-pane>
                        <el-tab-pane
                          label="控股"
                          name="3"
                        >
                          <template>
                            <div class="options-selects">
                              <el-option
                                v-for="(item, index) of correlateListHolding"
                                :key="index"
                                :label="item.name"
                                :value="item.id"
                              >
                                <p class="options-line">
                                  <span
                                    class="options-name"
                                    v-maia-ellipsis
                                  >{{ item.name }}</span>
                                  <span class="options-type">{{ item.typeName }}</span>
                                </p>
                              </el-option>
                            </div>
                          </template>
                          <p
                            v-if="correlateListHolding.length == 0"
                            class="el-select-dropdown__empty"
                          >暂无数据</p>
                        </el-tab-pane>
                        <el-tab-pane
                          label="固定资产"
                          name="7"
                        >
                          <template>
                            <div class="options-selects">
                              <el-option
                                v-for="(item, index) of correlateListFixed"
                                :key="index"
                                :label="item.name"
                                :value="item.id"
                              >
                                <p class="options-line">
                                  <span
                                    class="options-name"
                                    v-maia-ellipsis
                                  >{{ item.name }}</span>
                                  <span class="options-type">{{ item.typeName }}</span>
                                </p>
                              </el-option>
                            </div>
                          </template>
                          <p
                            v-if="correlateListFixed.length == 0"
                            class="el-select-dropdown__empty"
                          >暂无数据</p>
                        </el-tab-pane>

                      </el-tabs>
                    </div>
                    <div
                      slot="empty"
                      class="correlate-options"
                      v-loading="correlateLoading"
                    >
                      <p class="el-select-dropdown__empty">暂无数据</p>
                    </div>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item
                  label="是否需要付款:"
                  prop="needPay"
                >
                  <el-radio-group v-model="contractForm.needPay" @change="moneyCheck(contractForm.needPay)">
                    <el-radio label="Y">是</el-radio>
                    <el-radio label="N">否</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
              <!-- 是否供方合同 -->
              <el-col :span="6">
                <el-form-item
                  label="是否供方合同:"
                  prop="supplyStoreFlag"
                >
                  <el-radio-group v-model="contractForm.supplyStoreFlag" @change="changeContractSource">
                    <el-radio label="1">是</el-radio>
                    <el-radio label="0">否</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="6">
                <el-form-item
                  label="形成方式:"
                  prop="forType"
                >
                  <el-select
                    v-model="contractForm.forType"
                    style="width: 100%"
                    :popper-append-to-body="false"
                  >
                    <template v-for="(item, index) of forTypeList">
                      <el-option
                        :key="index"
                        :label="item.label"
                        :value="item.code"
                      />
                    </template>
                  </el-select>
                </el-form-item>
              </el-col>
               <el-col :span="6" v-if="contractForm.projectGoalFlag == 'Y' && projectTypeList.indexOf('3') != -1  && contractForm.contractType == 'COMMON'">
                <el-form-item
                  label="所属阶段:"
                  prop="kgStage"
                >
                  <el-select v-model="contractForm.kgStage">
                    <el-option label="投资阶段" value="1"/>
                    <el-option label="退出阶段" value="2"/>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="6" v-if="contractForm.projectGoalFlag == 'Y' && projectTypeList.indexOf('7') != -1 && contractForm.contractType == 'COMMON'">
                <el-form-item
                  label="具体合同类型:"
                  prop="fixContractType"
                >
                  <el-select v-model="contractForm.fixContractType">
                    <el-option label="CapEX合同" value="1"/>
                    <el-option label="OpEX合同" value="2"/>
                    <el-option label="通用合同" value="0"/>
                  </el-select>
                </el-form-item>
              </el-col>
              <!-- <el-col :span="6">
                <el-form-item
                  label="关联已解除合同:"
                  prop="reliefContractNo"
                >
                  <el-input
                    v-model="contractForm.reliefContractNo"
                    placeholder="请输入合同编号进行关联"
                  />
                </el-form-item>
              </el-col> -->
            </el-row>
          </section>
        </div>
        <!-- 签约主体 -->
        <div class="detail-card">
          <header class="card-header">
            <h4 class="second-title">签约主体</h4>
          </header>
          <section class="card-content">
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item
                  label="我方签约主体:"
                  prop="ourCompany"
                >
                  <el-select
                    multiple
                    filterable
                    remote
                    placeholder="请输入不少于4个字的主体名称进行查询"
                    :remote-method="searchOurCompany"
                    v-model="contractForm.ourCompany"
                    style="width: 100%"
                    :popper-append-to-body="false"
                    ref="ourCompany"
                    @focus="clearOptions"
                  >
                    <template v-for="(item, index) of ourCompanyList">
                      <el-option
                        :key="index"
                        :label="item.name"
                        :value="item.id"
                      />
                    </template>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                  label="对方签约主体:"
                  prop="supplierCompany"
                >
                  <el-select
                    multiple
                    filterable
                         remote
                    placeholder="请输入不少于4个字的主体名称进行查询"
                    :remote-method="searchSupplierCompany"
                    v-model="contractForm.supplierCompany"
                    style="width: 100%"
                    :popper-append-to-body="false"
                    ref="supplierCompany"
                    @focus="clearOptions"
                  >
                     <div slot="" class="option-wrapper">
                      <template>
                        <el-option
                          v-for="(item, index) of supplierCompanyListNew"
                          :key="index"
                          :label="item.name"
                          :value="item.id"
                        >
                          <p class="option-title">
                            <span class="option-name" v-maia-ellipsis>{{ item.name }}</span>
                            <span :class="['tag', item.type]">{{ item.type === 'T02' ? '被投方' : (item.type === 'T03' ? '合作方' : '供方') }}</span>
                          </p>
                        </el-option>
                      </template>
                    </div>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </section>
        </div>
        <!-- 招采信息 -->
        <div
          class="detail-card"
        >
          <header class="card-header">
            <h4 class="second-title">招采信息</h4>
          </header>
          <section class="card-content">
            <el-row :gutter="20">
              <el-col :span="18">
                <el-form-item
                  label="招标/比价/直委:"
                  prop="orderMsg"
                >
                  <el-input
                    type="textarea"
                    size="small"
                    :autosize="{ minRows: 2, maxRows: 6 }"
                    maxlength="5000"
                    show-word-limit
                    placeholder="请输入招标/比价/直委说明信息"
                    v-model="contractForm.orderMsg"
                  >
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <Attach
              :buttonName="pickButtonName"
              :showRemark="false"
              :fileUploadList="forminingfileList"
              @upload="pickUploadFile"
            >
            </Attach>
          </section>
        </div>
        <div>
          <!-- 合同金额 -->
          <div class="detail-card">
            <header class="card-header">
              <h4 class="second-title">合同金额</h4>
            </header>
            <section class="card-content">
              <el-row :gutter="20">
                <el-col :span="6">
                  <el-form-item
                    label="币种:"
                    prop="currency"
                  >
                    <el-select
                      v-model="contractForm.currency"
                      style="width: 100%"
                      :popper-append-to-body="false"
                      :clearable="true"
                    >
                      <template v-for="(item, index) of currencyList">
                        <el-option
                          :key="index"
                          :label="item.value"
                          :value="item.code"
                        />
                      </template>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item
                    label="合同金额(含税)(A):"
                    prop="contractAmount"
                    class="label-style"
                  >
                    <el-input
                      class="amount-input"
                      v-model="contractForm.contractAmount"
                      placeholder="请输入金额"
                      @input="limitInput($event, 'contractAmount')"
                      @keydown.enter.prevent.native="handle"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item
                    label="不计成本金额(B):"
                    prop="costAmount"
                    class="label-style"
                  >
                    <el-input
                      class="amount-input"
                      v-model="contractForm.costAmount"
                      placeholder="请输入金额"
                      @input="limitInput($event, 'costAmount')"
                      @keydown.enter.prevent.native="handle"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item
                    label="有效签约金额(C=A-B):"
                    prop="effContractAmount"
                    class="label-style"
                  >
                    <el-input
                      class="amount-input"
                      placeholder="-"
                      v-model="contractForm.effContractAmount"
                      disabled
                    />
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="6">
                  <el-form-item
                    label="发票类型:"
                    prop="invoiceType"
                  >
                    <el-select
                      v-model="contractForm.invoiceType"
                      style="width: 100%"
                      :popper-append-to-body="false"
                    >
                      <template v-for="(item, index) of invoiceTypeList">
                        <el-option
                          :key="index"
                          :label="item.label"
                          :value="item.code"
                        />
                      </template>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item
                    label="可抵扣税率:"
                    prop="deductibleTaxRate"
                  >
                    <el-input
                      class="amount-input"
                      placeholder="请输入可抵扣税率"
                      v-model="contractForm.deductibleTaxRate"
                      @input="limitInput($event, 'deductibleTaxRate')"
                      @keydown.enter.prevent.native="handle"
                    >
                      <i
                        slot="suffix"
                        :style="{'color':'#999','paddingRight': 6+'px'}"
                      > % </i>
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item
                    label="可抵扣税额:"
                    prop="deductibleTax"
                  >
                    <el-input
                      disabled
                      class="amount-input"
                      placeholder="-"
                      v-model="contractForm.deductibleTax"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item
                    prop="exContractAmount"
                    class="label-style"
                  >
                    <span slot="label">合同金额<br />(不含抵扣税):</span>
                    <el-input
                      v-model="contractForm.exContractAmount"
                      class="amount-input"
                      placeholder="-"
                      disabled
                    />
                  </el-form-item>
                </el-col>
              </el-row>
            </section>
          </div>
          <!-- 费项分摊 -->
          <div class="detail-card"  v-if="contractForm.needPay === 'Y'">
            <header class="card-header cost">
              <h4 class="second-title">费项分摊</h4>
              <button
                class="btn btn-primary"
                @click.prevent="addCostList"
              >
                <i class="el-icon-circle-plus-outline iconStyle"></i>
                新增
              </button>
            </header>
            <section class="card-content">
              <el-table
                :data="contractForm.costExpenseList"
                ref="costExpenseList"
                style="width: 100%"
                border
                fit
              >
                <div
                  slot="empty"
                  class="empty-box"
                >
                  <button
                    class="btn"
                    @click.prevent="addCostList"
                  >
                    <i class="el-icon-circle-plus-outline"></i>
                  </button>
                  <div><span>请点击【新增】按钮，添加费项分摊信息</span></div>
                </div>
                <el-table-column
                  label="责任人"
                  prop="operatorNo"
                >
                  <template slot-scope="scope">
                    <el-form-item
                      class="cost-card"
                      :prop="'costExpenseList.'+ scope.$index + '.operatorNo'"
                      :rules="costFormRules.operatorNo"
                    >
                      <el-select
                        filterable
                        v-model="scope.row.operatorNo"
                        size="small"
                      >
                        <el-option
                          v-for="(item, index) of dutyPeoList"
                          :key="index"
                          :label="item.name"
                          :value="item.id"
                        />
                      </el-select>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column
                  label="费项"
                  prop="costItemsId"
                >
                  <template slot-scope="scope">
                    <el-form-item
                      class="cost-card"
                      :prop="'costExpenseList.'+ scope.$index + '.costItemsId'"
                      :rules="costFormRules.costItemsId"
                    >
                      <el-select
                        filterable
                        v-model="scope.row.costItemsId"
                        size="small"
                      >
                        <el-option
                          v-for="(item, index) of costDetailList"
                          :key="index"
                          :label="item.F_CAPTION"
                          :value="item.FK_KMBM"
                        />
                      </el-select>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column
                  label="预算分摊部门"
                  prop="costItemsDept"
                >
                  <template slot-scope="scope">
                    <el-form-item
                      class="cost-card"
                      :prop="'costExpenseList.'+ scope.$index + '.costItemsDept'"
                      :rules="costFormRules.costItemsDept"
                    >
                      <el-select
                        filterable
                        v-model="scope.row.costItemsDept"
                        size="small"
                      >
                        <el-option
                          v-for="(item, index) of costShareDepartList"
                          :key="index"
                          :label="item.F_CAPTION"
                          :value="item.F_ID"
                        />
                      </el-select>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column
                  label="分摊金额(含税)"
                  prop="amount"
                  align="right"
                >
                  <template slot-scope="scope">
                    <el-form-item
                      class="cost-card"
                      :prop="'costExpenseList.'+ scope.$index + '.amount'"
                      :rules="costFormRules.amount"
                    >
                      <el-input
                        size="small"
                        v-model="scope.row.amount"
                        class="amount-input"
                        @input="()=>{ scope.row.amount = limitInput(scope.row.amount) }"
                        @keydown.enter.native.prevent="handle"
                      />
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column
                  label="操作"
                  width="60px"
                  align="left"
                >
                  <template slot-scope="scope">
                    <div>
                      <el-button
                        class="operate-btn"
                        type="text"
                        @click="dealCost(scope.$index, scope.row)"
                      > 删除
                      </el-button>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </section>
          </div>
          <!-- 合同付款约定 -->
          <div class="detail-card" v-if="contractForm.needPay === 'Y'">
            <header class="card-header describe">
              <h4 class="second-title">合同付款约定</h4>
              <span>请说明首付款、过程付款、尾款的付款条件、时间及金额</span>
            </header>
            <section class="card-content">
              <el-row>
                <el-col :span="18">
                  <el-form-item
                    label="说明信息:"
                    prop="payAgreement"
                  >
                    <el-input
                      type="textarea"
                      size="small"
                      placeholder="请输入内容"
                      :autosize="{ minRows: 2, maxRows: 6 }"
                      maxlength="5000"
                      show-word-limit
                      v-model="contractForm.payAgreement"
                    >
                    </el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </section>
          </div>
        </div>
        <!-- 合同说明 -->
        <div class="detail-card">
          <header class="card-header describe">
            <h4 class="second-title">合同说明</h4>
            <span>请明确本次合同签订的范围及目的，以供审批人直观审批</span>
          </header>
          <section class="card-content">
            <el-row>
              <el-col :span="18">
                <el-form-item
                  label="说明信息:"
                  prop="contractNote"
                >
                  <el-input
                    type="textarea"
                    size="small"
                    placeholder="请输入内容"
                    :autosize="{ minRows: 2, maxRows: 6 }"
                    maxlength="5000"
                    show-word-limit
                    v-model="contractForm.contractNote"
                  >
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </section>
        </div>
        <!-- 文档 -->
        <div class="detail-card no-bottom-border">
          <header class="card-header">
            <h4 class="second-title">文档</h4>
          </header>
          <section class="card-content">
            <Attach
              ref="attach"
              :label="label"
              :prop="'contractFiles'"
              :fileUploadList="contractForm.contractFiles"
              @upload="uploadFile"
            >
            </Attach>
          </section>
        </div>
      </el-form>
    </section>
    <footer class="footer fix_footer">
      <div class="btnBox">
        <button
          class="btn btn-lucency"
          @click="deleteConract"
        >删除</button>
        <button
          class="btn btn-primary btn-temporary"
          @click="storage"
          :disabled="storageDisabled"
          :class="storageDisabled?'storage_disabled':''"
        >
          暂存
        </button>
        <button
          class="btn btn-primary"
          @click="approval"
        >下一步</button>
      </div>
    </footer>
  </div>
</template>

<script>
const optionDataFilter = function (keyWord, originList, config = { limit: 4, nameKey: 'name' }) {
  // 搜索字符小于4个字不展示搜索结果
  const { limit, nameKey } = config
  if (keyWord.length < limit) {
    return []
  }
  let res = originList.filter(element => {
    let str = element[nameKey].toLocaleLowerCase()
    let lowKeyWord = keyWord.toLocaleLowerCase()
    return str.indexOf(lowKeyWord) !== -1
  })
  return res
}
import TitleBar from "@/components/common/TitleBar.vue"
import Attach from "@/components/common/Attach.vue"
import utils from '@/utils/index'

export default {
  name: "conEdit",
  components: {
    TitleBar,
    Attach,
  },
  data() {
    //附件校验规则
    var contractFiles = (rule, value, callback) => {
      let index = this.contractForm.contractFiles.findIndex(v => v.status === "success")
      console.log(index, ",,,,,")
      if (index == '-1') {
        callback(new Error('附件不能为空'))
      } else  {
        callback()
      }
    }
    return {
      relatedProjectList: [],
      label: "附件：",
      pickButtonName: "上传招采附件",
      prevUrl: "", // 上一页页面地址
      title: "合同详情", // 标题
      pageType: "",
      approvalDetailUrl: "", // 审批路径
      id: "", // 合同ID
      showSerial: false,
      dateTip: false, // 日期提示
      rightNow: "", // 当前日期
      innerBtnBox: true,
      //合同详情页状态
      hasStatus: {
        show: true,
        status: ""
      },
      storageDisabled: false, // 暂存按钮禁用
      // 合同表单数据
      contractForm: {
        // contractAmount: null,
        fixContractType:'0',
        supplyStoreFlag: '1',
        invoiceType: null,
        contractType: "COMMON", //合同类型
        needPay: "Y", // 是否需要付款
        ourCompany: [], // 我方签约主体
        supplierCompany: [], // 对方签约主体
        currency: "CNY", // 币种
        aboveQuotaType: "RATE",
        costExpenseList: [], // 费项列表
        attachFileList: [], //附件列表
        contractFiles: [], //合同附件
        projectGoalFlag: "N", // 是否项目目的
        relevanceProjectInfo: [], // 关联项目选项
        //contractDept: "", // 经办部门
      },
      // 费项表单数据
      costForm: {},
      // 表单验证规则
      formRules: {
        contractType: [{ required: true, message: "请选择合同类型" }],
        contractName: [{ required: true, message: "请输入合同名称" }],
        contractDate: [{ required: true, message: "请选择经办时间" }],
        needPay: [{ required: true, message: "请选择是否需要付款" }],
        ourCompany: [{ required: true, message: "请选择我方签约主体" }],
        supplierCompany: [{ required: true, message: "请选择对方签约主体" }],
        currency: [{ required: true, message: "请选择币种" }],
        contractAmount: [{ required: true, message: "请输入合同金额" }],
        invoiceType: [{ required: true, message: "请选择发票类型" }],
        projectGoalFlag: [{ required: true, message: "请选择关联项目" }],
        relevanceProjectInfo: [{ required: true, message: "请选择关联项目" }],
        contractFiles: [{ required: true, validator: contractFiles }],
        supplyStoreFlag: [{ required: true, message: "请选择是否供方合同" }],
        fixContractType: [{ required: true, message: "请选择具体合同类型" }],
        kgStage: [{ required: true, message: "请选择所属阶段" }]


        // contractDept: [{ required: true, message: "请选择经办部门" }]
      },

      // 费项表单验证
      costFormRules:{
        operatorNo: [{ required: true, message: "请选择责任人" }],
        costItemsId: [{ required: true, message: "请选择费项" }],
        costItemsDept: [{ required: true, message: "请选择费项分摊部门" }],
        amount: [{ required: true, message: "请输入金额"}]
      },

      // 招采信息附件列表
      forminingfileList: [],

      //合并后的附件
      totalFileList: [],

      // 合同类型列表
      contypeList: [
        {
          label: "通用合同",
          value: "COMMON"
        },
        {
          label: "礼品采购",
          value: "GIFT"
        },
        {
          label: "品牌活动合同",
          value: "ACTIVITY"
        },
        {
          label: "董事长事务合同",
          value: "BUSINESS"
        }
      ],
      // 经办人所属部门列表
      // orgList:[],
      // 形成方式列表
      forTypeList: [],
      // 币种列表
      currencyList: [],
      // 发票类型列表
      invoiceTypeList: [],

      // 我方签约主体列表
      ourCompanyList: [],
      // 对方签约主体列表
      supplierCompanyList: [],
      supplierCompanyListNew: [],

      // 所有主体集合
      subList: [],

      // 费项责任人选择列表
      dutyPeoList: [],
      // 费项明细选择列表
      costDetailList: [],
      // 费项预算分摊部门列表
      costShareDepartList: [],

      // 关联项目列表-所有
      correlateListAll: [],
      // 关联项目列表-直投
      // correlateListDire: [],
      // 关联项目列表-债券
      correlateListBond: [],
      // 关联项目列表-控股
      correlateListHolding: [],           
      // 关联项目列表-一级基金
      correlateListStairFund: [],
      // 关联项目列表-二级基金
      correlateListSecondaryFund: [],
      // 关联项目列表-股票
      correlateListStock: [],
      // 关联项目列表-固定资产
      correlateListFixed: [],
      // 关联项目列表-一级直投线索(项目源)
      correlateListClew: [],

      // 关联项目loading
      correlateLoading: false,

      // 关联项目下拉选项默认全部
      activeName: "0",

      // 全局loading
      loading: null,

      // 校验供方合同是否选择供方主体
      checkContractNext: true,
      supplierIdArray: [],
    };


  },
  mounted() {
    this.moneyCheck(this.contractForm.needPay, 'noClear')
  },
  methods: {
    handle() {},
    // 是否供方合同
    // 获取所有供方主体id存为数组
    getSupplierIdArray() {
      this.supplierCompanyList.forEach(item => {
        if (item.type === 'T04') {
          this.supplierIdArray.push(item.id)
        }
      })
    },
    // 校验是否供方合同并做处理
    checkContractSource() {
      this.checkContractNext = true
      if (this.contractForm.supplyStoreFlag === '1' && this.contractForm.supplierCompany) {
        this.contractForm.supplierCompany.forEach(item => {
          if(this.supplierIdArray && this.supplierIdArray.includes(item)) {
            this.checkContractNext = false
          }
        })
      }
      if (this.contractForm.supplyStoreFlag === '1' && this.contractForm.addSupplierCompany) {
        this.contractForm.addSupplierCompany.forEach(item => {
          if(this.supplierIdArray && this.supplierIdArray.includes(item)) {
            this.checkContractNext = false
          }
        })
      }
      if (this.contractForm.supplyStoreFlag === '0') {
        this.checkContractNext = false
      }
    },
    // 改变合同来源
    changeContractSource(val = '1') {
      if (val === '0') {
        this.supplierCompanyListNew = []
        this.supplierCompanyList.forEach(item => {
          if (item.type === 'T02' || item.type === 'T03') {
            this.supplierCompanyListNew.push(item)
          }
        })
        if (this.contractForm.supplierCompany) {
          this.contractForm.supplierCompany.forEach((items, index) => {
            if (this.supplierIdArray && this.supplierIdArray.includes(items)) {
              this.contractForm.supplierCompany.splice(index, 1)
            }
          })
        }
      } else {
        this.supplierCompanyListNew = this.supplierCompanyList
      }
    },
    moneyCheck(val, noClear) {
      if (val !== 'Y') {
        this.formRules.invoiceType[0].required = false
        if (!noClear) {
          if (this.contractForm.currency) {
            this.contractForm.currency = ''
          }
          if (this.contractForm.contractAmount) {
            this.contractForm.contractAmount = ''
          }
        }
      } else {
        this.formRules.invoiceType[0].required = true
        if (!this.contractForm.currency) {
          this.contractForm.currency = 'CNY'
        }
      }
      if (val !== 'Y' && !this.contractForm.contractAmount && !this.contractForm.currency) {
        this.formRules.currency[0].required = false
        this.formRules.contractAmount[0].required = false
      } else {
        this.formRules.currency[0].required = true
        this.formRules.contractAmount[0].required = true
      }
    },
    //从子组件获取附件列表
    uploadFile(params){
      if (params.length > 0) {
        this.$refs.attach?.$refs.file_rule?.clearValidate()
      }
      params.forEach(item => {
        item.fileCategory = "CONTRACT"
      });
      this.contractForm.contractFiles = params      
    },
    pickUploadFile(params){
      params.forEach(item => {
        item.fileCategory = "RECRUIT";
      });
      this.forminingfileList = params      
    },

    // 获取路径来源
    getUrl() {
      return new Promise(resolve => {
        let url = this.$route.path;
        let fdStart = url.indexOf("conedit");
        if (fdStart == 1) {
          // 编辑页
          this.pageType = "edit";
        } else {
          // 草稿页
          this.pageType = "draft";
        }
        let id = this.$route.params.id;
        this.id = id;
        resolve(id);
      })
    },

    // 查询合同信息
    getConInfo(id) {
      let params = { id };
      this.$request
      .post("/contract/queryContractById", { params })
      .then(res => {
        if (res.code && res.code === "200") {
          let data = res.data
          
          if(data.aboveQuotaScale) {
            // data.aboveQuotaScale = (Number(data.aboveQuotaScale) * 100).toFixed(2)
            data.aboveQuotaScale = (utils.BigNumberCacl(data.aboveQuotaScale, '100', '*', 'noFixed'))
          }

          this.contractForm = data
          this.contractForm.fixContractType = String(data.fixContractType||data.fixContractType===0? data.fixContractType: '')
          this.title = data.contractName
          this.hasStatus.status = data.contractStatus
          this.approvalDetailUrl = data.bpminnerUrl
          let tempArr = []
          if (data.projectId) {
            tempArr = data.projectId.split(',').filter(item => item).map(item => {
              return item - 0
            })
          }
          
          this.contractForm.relevanceProjectInfo = [...tempArr]
          data.deductibleTaxRate? this.contractForm.deductibleTaxRate = utils.BigNumberCacl(data.deductibleTaxRate, '100', '*', 'noFixed') : this.contractForm.deductibleTaxRate = null

          // 处理主体选择列表
          this.contractForm.ourCompany = utils.formatSubId(data.ourCompanyId)
          this.contractForm.supplierCompany = utils.formatSubId(data.supplierCompanyId)

          this.$set(this.contractForm, 'supplyStoreFlag', data.supplyStoreFlag || '1')
          // 是否供方合同
          this.changeContractSource(this.contractForm.supplyStoreFlag)

this.ourCompanyList = res.data.ourCompanyList || []
this.supplierCompanyListNew = res.data.supplierCompanyList || []
          // if (this.contractForm.ourCompany.length) {
          //   this.ourCompanyList = this.subList.T01.filter(element=>{
          //     return this.contractForm.ourCompany.indexOf(element.id)!==-1 
          //   })
          // } else {
          //   this.ourCompanyList = []
          // }
          // this.supplierCompanyList = (this.subList.T02).filter(element=>{
          //   return this.contractForm.supplierCompany.indexOf(element.id)!==-1 
          // })

          // 拆分招采附件和合同附件
          data.attachFileList.forEach((item, index) => {
            item.status = 'success'
            item.uid = 'a'+ index
          })
          let fileList = data.attachFileList
          this.forminingfileList = fileList.filter((item) => {
            return item.fileCategory == "RECRUIT"
          })
          this.contractForm.contractFiles = fileList.filter((item) => {
            return item.fileCategory == "CONTRACT";
          })
        }
        
        // 关闭全局loading
        if(this.loading) {
          this.loading.close()
        }
      }).catch((error) =>{
        // 关闭全局loading
        if(this.loading) {
          this.loading.close()
        }
        this.$message.error(error.message)
      })
    },

    // 获取主体信息列表
    getSubList() {
      return new Promise(resolve =>{
        this.$request.post("/enterprise/queryEnterpriseAll").then(res => {
          if(res.code && res.code === "200") {
            this.subList = res.data
            this.supplierCompanyList = res.data.T02
            this.getSupplierIdArray()
            resolve()
          }
        })
      })
    },
    // 获取费项列表责任人
    getCostDutePeo() {
      this.$request.post("/employee/queryEmployeeAll?scope=").then(res => {
        if(res.code && res.code === "200") {
          this.dutyPeoList = res.data
        }
      });
    },
    // 获取费项预算部门
    getCostDep() {
      this.$request.post("/cost/queryCostItemDept").then(res => {
        if(res.code && res.code === "200") {
          this.costShareDepartList = res.data
        }
      });
    },
    // 获取费项选择列表
    getCostList() {
      this.$request.post("/cost/queryCostItem").then(res => {
        if(res.code && res.code === "200") {
          this.costDetailList = res.data
        }
      });
    },

    // 获取当前日期
    getRightNowDate() {
      let nowDate = new Date();
      let y = nowDate.getFullYear();
      let m = (nowDate.getMonth() + 1).toString();
      let d = nowDate.getDate().toString();

      m = m.length == 1 ? "0" + m : m;
      d = d.length == 1 ? "0" + d : d;

      this.rightNow = y + "-" + m + "-" + d;
      this.contractForm.contractDate = this.rightNow;
    },

    // 经办日期更改后
    dateChange(val) {
      if (val < this.rightNow) {
        this.dateTip = true;
      } else {
        this.dateTip = false;
      }
    },

    // 切换是否关联项目
    correlateOptions(val){
      if(val === 'Y' && this.correlateListAll.length === 0) {
        this.getCorrelateList()
      }
    },
    
    // 获取关联项目
    getCorrelateList(correlateType) {
      this.correlateLoading = true
      let params = {
        type : correlateType || '0'
      }
      this.$request.post('/project/queryProjectList',{params}).then(res =>{
        this.correlateLoading = false
        if(res.code && res.code === '200' && res.data) {
          let data = res.data
          // 所有数据
          this.correlateListAll = data
          //  直投
          // this.correlateListDire = data.filter(item =>{
          //   return item.type === '1'
          // })
          // 债券
          this.correlateListBond = data.filter(item =>{
            return item.type === '2'
          })
          // 控股
          this.correlateListHolding = data.filter(item =>{
            return item.type === '3'
          })
          // 一级基金
          this.correlateListStairFund = data.filter(item =>{
            return item.type === '4'
          })
          // 二级基金
          this.correlateListSecondaryFund = data.filter(item =>{
            return item.type === '5'
          })
          // 股票
          this.correlateListStock = data.filter(item =>{
            return item.type === '6'
          })
          // 固定资产
          this.correlateListFixed = data.filter(item =>{
            return item.type === '7'
          })
          // 直投线索
          this.correlateListClew = data.filter(item =>{
            return item.type === '8'
          })
        }
      })
    },    

    // 处理金额输入框的校验
    limitInput(value, name) {
      return this.$limitRule(value,name,this.contractForm)
    },

    // 删除费项行数据
    dealCost(index, row) {
      this.contractForm.costExpenseList.splice(index, 1)
    },
    // 增加费项行
    addCostList() {
      this.contractForm.costExpenseList.unshift({});
    },

    // 下一步按钮（提交）
    approval() {
      this.checkContractSource()
      if (this.checkContractNext) {
        this.$message.error('供方合同请至少选择一个供方主体')
        return
      }
      if(this.contractForm.contractName.trim() === '') {
        this.$message({
          type: "error",
          message: "合同名称不能为空"
        })
        return
      }
      this.$refs["contractForm"].validate(valid => {
         if (valid) {
          this.loading = this.$loading({
            fullscreen: true,
            background: "rgba(255, 255, 255, 0.8)",
            text: "正在保存数据..."
          })

          this.saveContract('contract').then(res => {
            this.$message({
              type: "success",
              message: "保存成功，即将跳转审批页面",
              duration: 1500,
              onClose: () => {
                var pWin = window.open('_blank')
                pWin.location = res
                window.opener = null;
                window.open("about:blank", "_top").close()
              }
            })
          }).catch((error) =>{
            // 关闭全局loading
            if(this.loading) {
              this.loading.close()
            }
            this.$message.error(error.message)
          })
        }else {
          this.$message({
            type: "error",
            message: "请填写必填项",
            duration: 1500
          })
        }
      });
    },

    // 删除合同
    deleteConract() {
      this.$confirm("是否立即删除此合同，删除后，将无法恢复", "删除文件", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "info",
        lockScroll: false
      })
        .then(() => {
          let params = {
            id: this.id
          };
          this.$request
            .post("/contract/deleteContract", { params })
            .then(res => {
              if (res.code && res.code === "200" && res.data == "SUCCESS") {
                this.$message({
                  type: "SUCCESS",
                  message: "删除成功",
                  duration: 1000,
                  onClose: () => {
                    // 关闭当前页
                    window.opener = null;
                    window.open("about:blank", "_top").close()
                  }
                });
              }
            }).catch(error => {
              this.$message.error('删除失败,请检查合同是否存在')
            })
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          })
        })
    },
    //暂存
    storage() {
      if(this.contractForm.contractName.trim()) {
        this.storageDisabled = true
        this.saveContract('draft').then(res => {
          this.$message({
            type: "success",
            message: "暂存成功",
            duration: 1000,
          }) 
        })
      }else {
        this.$message({
          type: 'error',
          message: "请填写正确的合同名称",
          duration: 1000
        })
      }
    },

    // 保存调用接口
    saveContract(type) {          
      //合并招采附件，合同附件，去除保存不成功附件
      this.totalFileList = this.contractForm.contractFiles.concat(
        this.forminingfileList
      )
      //将上传完成的文件放入contractForm
      var temp = []
      this.totalFileList.forEach(item => {
        let index = temp.findIndex(v => v.uid === item.uid)
        if ("success" === item.status && "-1" == index) {
          if(item.partFiles) {
            item.partFiles = ''
          }
          temp = [
            {...item},
            ...temp
          ]
        }
      })
      this.contractForm.attachFileList = temp;

      // 处理费项数据结构
      if(this.contractForm.costExpenseList.length !== 0) {
        let costList = this.contractForm.costExpenseList
        costList.forEach(item => {
          this.costDetailList.forEach(it =>{
            if(it.FK_KMBM === item.costItemsId) {
              item.costItemsName = it.F_CAPTION
            }
          })
          this.costShareDepartList.forEach(it => {
            if(it.F_ID === item.costItemsDept) {
              item.costItemsDeptName = it.F_CAPTION
            }
          })
        })
      }

      this.contractForm.contractName = this.contractForm.contractName.trim()

      let params = {...this.contractForm}
      params.ourCompany = this.$formatSubList("ourCompany", this.contractForm)
      params.supplierCompany = this.$formatSubList("supplierCompany", this.contractForm)
      params.fixContractType = this.contractForm.projectGoalFlag == 'Y' && this.projectTypeList.indexOf('7') != -1 && this.contractForm.contractType == 'COMMON'? this.contractForm.fixContractType : null
      params.kgStage = this.contractForm.projectGoalFlag == 'Y' && this.projectTypeList.indexOf('3') != -1 && this.contractForm.contractType == 'COMMON'? this.contractForm.kgStage : null
      // params.otherCompany = this.$formatSubList("otherCompany", this.contractForm)
      // params.payeeCompany = this.$formatSubList("payeeCompany", this.contractForm)
      if(this.contractForm.relevanceProjectInfo) {
        var idList = []
        var _arr = JSON.parse(JSON.stringify(this.contractForm.relevanceProjectInfo))
        this.relatedProjectList.forEach(item => {
          _arr.forEach((x, index) => {
            if (item.name === x) {
              idList.push(item.id)
              _arr.splice(index, 1)
            }
          })
        })
        // params.relevanceProjectInfo = this.contractForm.relevanceProjectInfo.join(',')
        params.relevanceProjectInfo = _arr.concat(idList).join(',')
      }
      if(this.pageType === "draft" && type === 'draft') {
        params.contractStatus = 'draft'
      }
      if(this.pageType === "draft" && type === 'contract') {
        params.contractStatus = ''
      } 
      if(this.pageType === "edit"){
        params.contractStatus = this.contractForm.contractStatus
      }
      params.id = this.id
      return new Promise(resolve => {
        this.$request.post('/contract/addContract',{params}).then(res => {
       
          // 关闭全局loading
          if(this.loading) {
            this.loading.close()
          }
          this.storageDisabled = false
          if(res.code && res.code === "200" && res.data) {
            resolve(res.data)
          }
        }).catch((error) =>{
          // 关闭全局loading
          if(this.loading) {
            this.loading.close()
          }
          this.$message.error(error.message)
        })
      })
    },

    // 查看审批结果
    goApproveDetail() {
      if(this.approvalDetailUrl) {
        window.open(this.approvalDetailUrl)
      } else {
        window.open(`/nobpm.html`)
      }
      
    },

    // 获取当前登录人的部门
    // getCurrentOrg() {
    //   this.$request.post("/org/getOrgByCurrUser").then(res => {
    //     if(res.code === '200' && res.data) {
    //       this.orgList = res.data
    //     }
    //   })
    // }

    // 远程搜索我方主体
    searchOurCompany(keyWords){
      this.ourCompanyList = this.$optionDataFilter(keyWords,this.subList.T01)
    },

    // 远程搜索对方主体
    searchSupplierCompany(keyWords){
      this.supplierCompanyListNew = this.$optionDataFilter(keyWords,this.subList.T02, { limit: 2, nameKey: 'name' })
    },

    clearOptions() {
      this.ourCompanyList = []
      this.supplierCompanyListNew = []
    }

  },
  computed: {
    listenChange () {
        const {contractAmount, costAmount, exContractAmount, deductibleTaxRate } = this.contractForm
        return {contractAmount, costAmount, exContractAmount, deductibleTaxRate }
      },
    projectTypeList() {
      if(!this.contractForm || !this.contractForm.relevanceProjectInfo) return []
      let arr = this.contractForm.relevanceProjectInfo.map(element=> {
        let target = this.correlateListAll.find(pro=> pro.id ==element)
        console.log(target)
        if(target) {
          return target.type
        }
      })
      return arr
    }
  },
  watch: {
    listenChange: {
      handler(val, oldVal) {
        //contractAmount  合同金额（含税）
        //effContractAmount  有效签约金额
        //costAmount  不急成本金额
        //deductibleTax  可抵扣税额
        //exContractAmount  合同金额（不含抵扣税）
        //deductibleTaxRate  可抵扣税率

        if (val.contractAmount) {
          // 计算有效签约金额
          if (val.costAmount) {
            this.contractForm.effContractAmount =
              utils.BigNumberCacl(this.contractForm.contractAmount, this.contractForm.costAmount, '-')
          } else {
            this.contractForm.effContractAmount =
              utils.BigNumberCacl(this.contractForm.contractAmount, '0', '-')
          }

          // 计算可抵扣税额 合同金额（不含抵扣税）
          if (val.deductibleTaxRate) {
            let middle = utils.BigNumberCacl(this.contractForm.contractAmount, (1 + (val.deductibleTaxRate / 100)), '/')
            let tax =  utils.BigNumberCacl((val.deductibleTaxRate / 100), middle, '*')
            this.contractForm.deductibleTax = tax
            this.contractForm.exContractAmount = utils.BigNumberCacl(this.contractForm.contractAmount, this.contractForm.deductibleTax, '-')
              
          } else {
            this.contractForm.exContractAmount = utils.BigNumberCacl(this.contractForm.contractAmount, '0', '-')
            this.contractForm.deductibleTax = null
          }
        } else {
          this.contractForm.effContractAmount = null
          this.contractForm.exContractAmount = null
          this.contractForm.deductibleTax = null
        }

        // if(val.needPay === 'Y' && oldVal.needPay === 'N') {
        //   this.payeeCompanyList = ([...this.subList.T02,...this.subList.T03]).filter(item => {
        //     return this.contractForm.payeeCompany.indexOf(item.id) !== -1 
        //   })
        // }

        // if(!val.contractName) {
        //   this.storageDisabled = true
        // } else {
        //   this.storageDisabled = false
        // }

      },
      deep: true
    },
    pageType(newVal) {
      if (newVal == "edit") {
        this.hasStatus.show = true;
        this.showSerial = true;
        this.prevUrl = "/";
      } else {
        this.hasStatus.show = false;
        this.showSerial = false;
        this.prevUrl = "/draft";
      }
    },
    // 'contractForm.needPay': {
    //   immediate: false,
    //   handler: function (val) {
    //     this.moneyCheck(val)
    //   }
    // },
    'contractForm.currency': function(val) {
      if (this.contractForm.needPay === 'N') {
        if (val) {
          this.formRules.contractAmount[0].required = true
        } else {
          this.formRules.contractAmount[0].required = false
        }
      }
    },
    'contractForm.contractAmount': function(val) {
      if (this.contractForm.needPay === 'N') {
        if (val) {
          this.formRules.currency[0].required = true
        } else {
          this.formRules.currency[0].required = false
        }
      }
    },
  },
  created() {
    // 打开全局loading
    this.loading = this.$loading({ 
      fullscreen: true,
      background: "rgba(255, 255, 255, 0.8)",
      text: "正在加载数据..."
    })

    this.currencyList = JSON.parse(localStorage.getItem("currencyList"))
    this.invoiceTypeList = JSON.parse(localStorage.getItem("invoiceTypeList"))
    this.forTypeList = JSON.parse(localStorage.getItem("forTypeList"))

    this.getCorrelateList()

    this.getSubList().then(() =>{
      this.getUrl().then(res =>{
        this.getConInfo(res)
      })
    })

    this.getCostDutePeo()
    this.getCostDep()
    this.getCostList()
    // this.getCurrentOrg()
  },

};
</script>
<style lang="less" scoped>
.option-wrapper{
  ::v-deep .el-select-dropdown__item.selected::after{
    top: 0;
    color: #997236;
  }
  .option-title{
    display: flex;
    justify-content: space-between;
    padding-right: 30px;
    box-sizing: border-box;
    align-items: center;
    span.option-name{
      display: inline-block;
      width: 336px;
      color: #606266;
      font-size: 14px;
    }
    .tag{
      width: 48px;
      height: 18px;
      text-align: center;
      line-height: 18px;
      background: #F7EBD4;
      border-radius: 2px;
      font-size: 10px;
      &.T02{
        color: #7AA2F4 !important;
        background: #EEF3FD;
      }
      &.T03{
        color: #997236 !important;
        background: #F7EBD4;
      }
      &.T04{
        color: #50AC94 !important;
        background: #E0F7F1;
      }
    }
  }
}

.page-box {
  .list-title {
    box-sizing: border-box;
    width: 100%;
    height: 48px;
    line-height: 40px;
    padding: 6px 12px;
    border-bottom: 1px solid #eaeaea;
  }
  .juniorTitle {
    font-size: 16px;
    font-weight: 600;
    margin-left: 12px;
  }

  .content-box {
    .detail-card {
      .card-content {
        /deep/ .el-radio__input.is-checked .el-radio__inner {
          border-color: #efd193;
          background: #efd193;

          &::after {
            background-color: #323232;
            border-radius: 50%;
          }
        }

        .el-radio__label {
          color: #323232;
        }
        /deep/ .el-radio__input.is-checked + .el-radio__label {
          color: #323232;
        }
      }
      .describe {
        h4,
        span {
          display: inline-block;
        }
        span {
          color: #999999;
          font-size: 12px;
          margin-left: 6px;
        }
      }

      .correlate {
        margin-left: -120px;
        
        .correlate-options {
          width: 580px;
        }

        .correlate-options /deep/ .el-tabs__header {
          padding: 0 !important;
        }
        .correlate-options /deep/ .el-tabs__item {
          color: #5b5b5b;
          font-weight: 600;
          width: 70px !important;
        }

        .options-selects {
          .selected {
            color: #997236;

            &::after {
              top: 0;
            }
          }

          .options-line {
            box-sizing: border-box;
            max-width: 560px;
            height: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-right: 20px;

            .options-name {
              display: inline-block;
              height: 100%;
              flex: 1;
            }
            .options-type {
              display: inline-block;
              width: 60px;
              height: 22px;
              line-height: 22px;
              background-color: #f7ebd4;
              text-align: center;
              color: #997236;
              font-size: 10px;
            }
          }
        }
      }

      .date-tip {
        font-size: 12px;
        position: relative;
        top: -8px;
        color: #808898;
      }
    }
  }

  .footer {
    .storage_disabled {
      &:hover {
        cursor: not-allowed;
      }
    }
  }
}
</style>
